import { login, logout } from "app/services";
import { auth, database } from "configs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const useAuthState = () => {
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const authStateHandler = async () => {
      try {
        auth.onAuthStateChanged((currentUser) => {
          if (!currentUser) return dispatch(logout());
          const dbRef = database
            .ref("hotelsData")
            .child(currentUser?.uid)
            .child("ownerInfo");
          dbRef.on("value", (snap) => {
            if (!snap.exists()) return auth.signOut();
            const availableUserData = {
              metadata: currentUser.metadata,
              displayName: currentUser.displayName,
              email: currentUser.email,
              photoURL: currentUser.photoURL,
              uid: currentUser.uid,
            };
            dbRef.update(availableUserData);
            dispatch(login(snap.val()));
          });
        });
      } catch (error) {
        console.log(error);
      }
    };
    authStateHandler();
  }, [dispatch]);
  return { isLoggedIn, user };
};

export default useAuthState;
