import { getArrFromSnap } from "@ashirbad/js-core";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { auth, database } from "../configs";
const useBookings = () => {
  const [bookings, setBookings] = useState([]);
  const isMounted = useRef(false);
  const fetchBookings = async () => {
    try {
      database
        .ref(`Bookings/Property/${auth.currentUser.uid}`)
        .on("value", (snap) => {
          const arr = getArrFromSnap(snap)
            ?.sort((a, b) => new Date(b?.timestamp) - new Date(a?.timestamp))
            .map((item, i) => ({
              ...item,
              totalCost: +item?.grandTotal?.totalCost,
              totalNights: moment(item?.checkOut).diff(
                moment(item?.checkIn),
                "days"
              ),
              displayName: item?.userDetails?.displayName,
              email: item?.userDetails?.email,
              phoneNumber: item?.userDetails?.phoneNumber,
              slno: i + 1,
            }));
          isMounted.current && setBookings(arr);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    isMounted.current = true;
    fetchBookings();
    return () => (isMounted.current = false);
  }, []);

  return {
    bookings,
    checkOutBookings: bookings
      ?.filter((booking) => booking?.bookingStatus === "checkOut")
      ?.map((booking, i) => ({ ...booking, slno: i + 1 })),
  };
};
export default useBookings;
