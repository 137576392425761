import ReactDOM from "react-dom";
import "styles/index.css";

import { Provider as ReduxProvider } from "react-redux";
import { store } from "app";
import Router from "Router";

ReactDOM.render(
  <ReduxProvider store={store}>
    <Router />
  </ReduxProvider>,
  document.getElementById("root")
);
