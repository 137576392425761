import { Loader } from "components/core";
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { PrivateRoutes, PublicRoutes } from "routes";
import { useAuthState, useFCMToken } from "hooks";

const Router = () => {
  const { isLoggedIn, user } = useAuthState();
  useFCMToken(user?.uid);
  if (user === null) return <Loader />;
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        {isLoggedIn ? <PrivateRoutes /> : <PublicRoutes />}
      </BrowserRouter>
    </Suspense>
  );
};

export default Router;
