import { lazy } from "react";
export const Login = lazy(() => import("./Login"));
export const ForgetPassword = lazy(() => import("./ForgetPassword"));
export const ChangePassword = lazy(() => import("./ChangePassword"));
export const PropertyRoomCategories = lazy(() =>
  import("./PropertyRoomCategories")
);
export const BookRoom = lazy(() => import("./BookRoom"));
export const BookingDetails = lazy(() => import("./BookingDetails"));
export const Bookings = lazy(() => import("./Bookings"));
export const Dashboard = lazy(() => import("./Dashboard"));
export const EditHotelRoomCategory = lazy(() =>
  import("./EditHotelRoomCategory")
);

export const PropertyBillings = lazy(() => import("./PropertyBillings"));
export const PropertyDetails = lazy(() => import("./PropertyDetails"));
export const HotelAnalytics = lazy(() => import("./HotelAnalytics"));
export const PropertyReports = lazy(() => import("./PropertyReports"));
export const Layout = lazy(() => import("./Layout"));
export const ManageOwnerInfo = lazy(() => import("./ManageOwnerInfo"));
export const MyNotifications = lazy(() => import("./MyNotifications"));
export const SetHotelLocation = lazy(() => import("./SetHotelLocation"));
export const Support = lazy(() => import("./Support"));
export const ViewAddEditHotelFeatures = lazy(() =>
  import("./ViewAddEditHotelFeatures")
);
export const ViewAddHotelCategoryPhotos = lazy(() =>
  import("./ViewAddHotelCategoryPhotos")
);
export const ViewAddHotelPhotos = lazy(() => import("./ViewAddHotelPhotos"));
export const ViewEditHotelDetails = lazy(() =>
  import("./ViewEditHotelDetails")
);
export const ViewHotelRoomCategories = lazy(() =>
  import("./ViewHotelRoomCategories")
);
export const PropertyManagement = lazy(() => import("./PropertyManagement"));
export const PropertyLocation = lazy(() => import("./PropertyLocation"));
