import { Route, Switch } from "react-router-dom";
import { Login, ForgetPassword } from "pages";

const PublicRoutes = () => {
  return (
    <>
      <Switch>
        <Route path="/" component={Login} exact />
        <Route path="/login" component={Login} exact />
        <Route path="/forget-password" component={ForgetPassword} exact />
      </Switch>
    </>
  );
};

export default PublicRoutes;
