import { LOGO } from "assets";

const Loader = () => {
  return (
    <div className="d-flex align-items-center justify-content-center w-100 h-100 position-absolute">
      <div className="center_div">
        <div className="rot"></div>
        <img src={LOGO} alt="" width="120px" className="loading" />
      </div>
    </div>
  );
};

export default Loader;
