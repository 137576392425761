import { FormControl, MenuItem, Select, TextField } from "@material-ui/core";
import { countries } from "constant";
const PhoneInput = ({
  value,
  onChange,
  name,
  error,
  helperText,
  onBlur,
  countryCode,
  onChangeCountryCode,
  countryCodeName,
  margin,
  variant,
}) => {
  return (
    <>
      <TextField
        margin={margin || "normal"}
        variant={variant || "outlined"}
        fullWidth
        value={value}
        onChange={onChange}
        name={name}
        error={error}
        helperText={helperText}
        onBlur={onBlur}
        InputProps={{
          startAdornment: (
            <div>
              <FormControl>
                <Select
                  value={countryCode || "+91"}
                  onChange={onChangeCountryCode}
                  autoWidth
                  variant="standard"
                  disableUnderline
                  name={countryCodeName}
                >
                  {countries?.map((country) => (
                    <MenuItem key={country.code} value={`+${country?.phone}`}>
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {` +${country?.phone}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ),
        }}
      />
    </>
  );
};

export default PhoneInput;
