import { auth, database } from "../configs";
import { useEffect, useRef, useState } from "react";

const useHotelsData = () => {
  const [hotelsData, setHotelsData] = useState({});
  const isMounted = useRef(false);

  const fetchHotelsData = async () => {
    try {
      await database
        .ref(`hotelsData/${auth.currentUser.uid}`)
        .on("value", (snap) => {
          isMounted.current &&
            setHotelsData({ ...snap.val(), uid: auth.currentUser.uid });
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    fetchHotelsData();
    return () => (isMounted.current = false);
  }, []);
  return {
    hotelsData,
  };
};

export default useHotelsData;
