import {
  ArtTrackTwoTone,
  BallotTwoTone,
  InfoTwoTone,
  LocationOn,
  PhotoTwoTone,
} from "@material-ui/icons";
import { useState } from "react";
import {
  PropertyRoomCategories,
  PropertyDetails,
  PropertyLocation,
  ViewAddEditHotelFeatures,
  ViewAddHotelPhotos,
} from "pages";
import { Paper, Tab, Tabs } from "@material-ui/core";

const PropertyManagement = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleChange = (event, newValue) => setSelectedTabIndex(newValue);

  return (
    <>
      <Paper square>
        <Tabs
          value={selectedTabIndex}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="icon label tabs example"
        >
          <Tab icon={<ArtTrackTwoTone />} label="Room Categories" />
          <Tab icon={<InfoTwoTone />} label="Property Details" />
          <Tab icon={<PhotoTwoTone />} label="Property Photos" />
          <Tab icon={<BallotTwoTone />} label="Property Features" />
          <Tab icon={<LocationOn />} label="Property Location" />
        </Tabs>
      </Paper>
      <section className="py-lg">
        {selectedTabIndex === 0 && <PropertyRoomCategories />}
        {selectedTabIndex === 1 && <PropertyDetails />}
        {selectedTabIndex === 2 && <ViewAddHotelPhotos />}
        {selectedTabIndex === 3 && <ViewAddEditHotelFeatures />}
        {selectedTabIndex === 4 && <PropertyLocation />}
      </section>
    </>
  );
};

export default PropertyManagement;
