import { useEffect, useRef, useState } from "react";
import { auth, database } from "../configs";
import { getArrFromSnap } from "@ashirbad/js-core";
const useNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const isMounted = useRef(false);
  const fetchNotifications = async () => {
    try {
      await database
        .ref(`Notifications/${auth.currentUser.uid}`)
        .on("value", (snap) => {
          const arr = getArrFromSnap(snap).map((item) => {
            return { ...item };
          });
          isMounted.current && setNotifications(arr);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    isMounted.current = true;
    fetchNotifications();
    return () => (isMounted.current = false);
  }, []);

  return {
    notifications,
  };
};
export default useNotifications;
