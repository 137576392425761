import firebase from "firebase";
import "firebase/auth";
import "firebase/analytics";
import "firebase/storage";
import "firebase/database";
import "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_Id,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
};

const app = firebase.initializeApp(firebaseConfig);

export const auth = app.auth();

export const database = app.database();

export const storage = app.storage();

export const analytics = app.analytics();

export const messaging = app.messaging();

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const GOOGLE_MAPS_API = process.env.REACT_APP_GOOGLE_MAPS_API;

export default app;
