import { database, messaging } from "configs";
import { useEffect } from "react";

const useFCMToken = (uid) => {
  useEffect(() => {
    "Notification" in window &&
      Notification.requestPermission(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          // Get FCM Token
          const vapidKey =
            "BIELBCUj2cCbi8GNKn8-zVtTV4OZA8Cc4aqjWn_Gfr9woSaNymJTvdgt51je5VZP_aI1iGCPt0DNs1ozQgKD9PU";
          messaging
            .getToken({
              vapidKey,
            })
            .then((fcmToken) => {
              uid &&
                database.ref(`hotelsData/${uid}/fcmTokenWeb`).set(fcmToken);
            });
        }
      });
  }, [uid]);
};

export default useFCMToken;
