import { getArrFromSnap } from "@ashirbad/js-core";
import { database } from "configs";
import { useEffect, useRef, useState } from "react";

const useHotelFeature = () => {
  const [hotelFeatures, setHotelFeatures] = useState([]);
  const isMounted = useRef(false);

  const fetchHotelFeatures = async () => {
    try {
      await database.ref(`HotelFeatures/`).on("value", (snap) => {
        const arr = getArrFromSnap(snap).map((item, i) => {
          return { ...item, sl: i + 1 };
        });
        isMounted.current && setHotelFeatures(arr);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    fetchHotelFeatures();
    return () => (isMounted.current = false);
  }, []);
  return {
    hotelFeatures,
  };
};

export default useHotelFeature;
