import { Route, Switch } from "react-router-dom";
import {
  BookRoom,
  BookingDetails,
  Bookings,
  ChangePassword,
  Dashboard,
  EditHotelRoomCategory,
  PropertyBillings,
  PropertyReports,
  ManageOwnerInfo,
  MyNotifications,
  SetHotelLocation,
  ViewAddEditHotelFeatures,
  ViewAddHotelCategoryPhotos,
  ViewAddHotelPhotos,
  ViewEditHotelDetails,
  ViewHotelRoomCategories,
  Layout,
  PropertyDetails,
  Support,
  HotelAnalytics,
} from "pages";
import PropertyManagement from "pages/PropertyManagement";

const PrivateRoutes = () => {
  return (
    <Layout>
      <Switch>
        <Route path="/" component={Dashboard} exact />
        <Route path="/about-property" component={PropertyDetails} exact />
        <Route path="/support" component={Support} exact />
        <Route path="/book-a-room" component={BookRoom} exact />
        <Route path="/booking-details" component={BookingDetails} exact />
        <Route path="/bookings" component={Bookings} exact />
        <Route path="/change-password" component={ChangePassword} exact />
        <Route path="/dashboard" component={Dashboard} exact />
        <Route
          path="/edit-hotel-room-category"
          component={EditHotelRoomCategory}
          exact
        />
        <Route path="/property-analytics" component={HotelAnalytics} exact />
        <Route path="/property-billings" component={PropertyBillings} exact />
        <Route
          path="/property-management"
          component={PropertyManagement}
          exact
        />
        <Route path="/property-reports" component={PropertyReports} exact />
        <Route path="/manage-owner-info" component={ManageOwnerInfo} exact />
        <Route path="/my-notifications" component={MyNotifications} exact />
        <Route path="/set-hotel-location" component={SetHotelLocation} exact />
        <Route
          path="/property-features"
          component={ViewAddEditHotelFeatures}
          exact
        />
        <Route path="/property-photos" component={ViewAddHotelPhotos} exact />
        <Route
          path="/view-add-hotel-category-photos"
          component={ViewAddHotelCategoryPhotos}
          exact
        />
        <Route
          path="/view-edit-hotel-details"
          component={ViewEditHotelDetails}
          exact
        />
        <Route
          path="/view-hotel-room-categories"
          component={ViewHotelRoomCategories}
          exact
        />
      </Switch>
    </Layout>
  );
};

export default PrivateRoutes;
